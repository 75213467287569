import React from "react"

import Input from "components/Input"

const Representative = ({ input, setInput, validationErrors }) => {
  return (
    <div className="order-basic-data__form">
      <div className="order-basic-data__form-divider" />
      <h4>Reprezentant 1</h4>
      <div className="row">
        <div className="col-sm-6">
          <Input
            type="text"
            label="Imię*"
            value={input.agent1Name}
            onChange={e => setInput({ ...input, agent1Name: e.target.value })}
            error={validationErrors.agent1Name}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Nazwisko*"
            value={input.agent1Surname}
            onChange={e =>
              setInput({ ...input, agent1Surname: e.target.value })
            }
            error={validationErrors.agent1Surname}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pełniona funkcja*"
            value={input.agent1Function}
            onChange={e =>
              setInput({ ...input, agent1Function: e.target.value })
            }
            error={validationErrors.agent1Function}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pesel*"
            value={input.agent1Pesel}
            onChange={e => setInput({ ...input, agent1Pesel: e.target.value })}
            error={validationErrors.agent1Pesel}
          />
        </div>
      </div>

      <h4>Reprezentant 2</h4>
      <div className="row">
        <div className="col-sm-6">
          <Input
            type="text"
            label="Imię"
            value={input.agent2Name}
            onChange={e => setInput({ ...input, agent2Name: e.target.value })}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Nazwisko"
            value={input.agent2Surname}
            onChange={e =>
              setInput({ ...input, agent2Surname: e.target.value })
            }
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pełniona funkcja"
            value={input.agent2Function}
            onChange={e =>
              setInput({ ...input, agent2Function: e.target.value })
            }
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pesel"
            value={input.agent2Pesel}
            onChange={e => setInput({ ...input, agent2Pesel: e.target.value })}
          />
        </div>
      </div>

      <h4>Reprezentant 3</h4>
      <div className="row">
        <div className="col-sm-6">
          <Input
            type="text"
            label="Imię"
            value={input.agent3Name}
            onChange={e => setInput({ ...input, agent3Name: e.target.value })}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Nazwisko"
            value={input.agent3Surname}
            onChange={e =>
              setInput({ ...input, agent3Surname: e.target.value })
            }
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pełniona funkcja"
            value={input.agent3Function}
            onChange={e =>
              setInput({ ...input, agent3Function: e.target.value })
            }
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pesel"
            value={input.agent3Pesel}
            onChange={e => setInput({ ...input, agent3Pesel: e.target.value })}
          />
        </div>
      </div>

      <h4>Reprezentant 4</h4>
      <div className="row">
        <div className="col-sm-6">
          <Input
            type="text"
            label="Imię"
            value={input.agent4Name}
            onChange={e => setInput({ ...input, agent4Name: e.target.value })}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Nazwisko"
            value={input.agent4Surname}
            onChange={e =>
              setInput({ ...input, agent4Surname: e.target.value })
            }
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pełniona funkcja"
            value={input.agent4Function}
            onChange={e =>
              setInput({ ...input, agent4Function: e.target.value })
            }
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pesel"
            value={input.agent4Pesel}
            onChange={e => setInput({ ...input, agent4Pesel: e.target.value })}
          />
        </div>
      </div>
    </div>
  )
}

export default Representative
