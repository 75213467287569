/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import "./styles.scss"

import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import scrollToElement from "scroll-to-element"
import { navigate } from "gatsby"
import axios from "axios"
import moment from "moment"
import { toast } from "react-toastify"

import { AppContext } from "context/app_context"
import { useCustomer } from "context/customer"
import { getFormattedCart, createCheckoutData } from "utils/functions"

import GET_CART from "queries/get-cart"
import CHECKOUT_MUTATION from "mutations/checkout"

import ArrowPrev from "assets/icons/order-actions-prev.svg"
import ArrowNext from "assets/icons/order-actions-next.svg"

const Actions = ({
  step,
  setStep,
  input,
  setInput,
  setPdf,
  handleValidationStepFirst,
  handleValidationStepSecond,
  handleValidationStepThird,
  handleValidationStepFourth,
}) => {
  const [cart, setCart] = useContext(AppContext)
  const { customer } = useCustomer()
  const [orderData, setOrderData] = useState(null)

  // Get Cart Data
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(data)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  // Checkout or CreateOrder Mutation.
  const [checkout, { loading: checkoutLoading }] = useMutation(
    CHECKOUT_MUTATION,
    {
      variables: {
        input: orderData,
      },
      onCompleted: async ({ checkout: { order } }) => {
        if (order?.paymentMethod === "tpay") {
          try {
            const { data } = await axios.post(
              "https://trust-finance.zenx.pl/wp-json/moja_metoda_platnosci/v1/init_tpay_payment/",
              {
                orderId: order?.databaseId.toString(),
                urlSuccess:
                  "https://trustfinances.pl/zamowienie-potwierdzenie/",
                urlError: "https://trustfinances.pl/zamowienie-niepowodzenie/",
              }
            )
            await refetch()
            window.location.href = data?.redirectUri
          } catch (error) {
            console.warn(error.response.data)
          }
        } else {
          await refetch()
          navigate(
            `/zamowienie-potwierdzenie/${
              input?.paymentMethod === "bacs"
                ? `?payment=bacs&sharesTotal=${input?.sharesTotal}&clientType=${input?.clientType}&firstName=${input?.firstName}&lastName=${input?.lastName}&pesel=${input?.pesel}&investor=${input?.investor}&krs=${input?.krs}`
                : ""
            }`
          )
        }
      },
      onError: error => {
        if (error) {
          console.warn(error.graphQLErrors[0].message)
        }
      },
    }
  )

  console.log(
    `?payment=bacs&sharesTotal=${input?.sharesTotal}&clientType=${input?.clientType}&firstName=${input?.firstName}&lastName=${input?.lastName}&pesel=${input?.pesel}&investor=${input?.investor}&krs=${input?.krs}`
  )

  // Handle form submit.
  const handleFormSubmit = e => {
    e.preventDefault()

    const checkOutData = createCheckoutData(input)
    setOrderData(checkOutData)
  }

  useEffect(() => {
    if (null !== orderData) {
      checkout()
    }
  }, [orderData])

  // console.log(data?.cart?.contents?.nodes[0]?.product?.node?.databaseId)
  // console.log(input?.productId)

  // PDF
  const generatePdf = async () => {
    setPdf(true)
    try {
      const {
        data: { pdf_path },
      } = await axios.post(
        "https://trust-finance.zenx.pl/wp-json/pdf-generator/v2/generate",
        {
          pdf_data: `{
            "{product_id}":"${input?.productId}",
            "{date}":"${moment().format("DD/MM/YYYY")}",
            "{pdf_file_name}":"${input.firstName}-${input.lastName}-${
            input.pesel
          }-trust-investment-projekt-15-${input.customID.substring(0, 7)}",
            "{clientType}":"${input.clientType}", 
            "{firstName}":"${input.firstName}", 
            "{secondFirstName}":"${input.secondFirstName}",
            "{lastName}":"${input.lastName}",
            "{pesel}":"${input.pesel}",
            "{partnerId}":"${input.partnerId}",
            "{idNumber}":"${input.idNumber}",
            "{phone}":"${input.phone}",
            "{bankAccount}":"${input.bankAccount}",
            "{email}":"${input.email}",
            "{taxOffice}":"${input.taxOffice}",
            "{investor}":"${input.investor}",
            "{krs}":"${input.krs}",
            "{court}":"${input.court}",
            "{department}":"${input.department}",
            "{regon}":"${input.regon}",
            "{nip}":"${input.nip}",
            "{sharesQuantity}":"${input.sharesQuantity}",
            "{shareEmissionPrice}":"${input.shareEmissionPrice}",
            "{sharesTotal}":"${input.sharesTotal}",
            "{sharesEmissionTotal}":"${input.sharesEmissionTotal}",
            "{street}":"${input.street}",
            "{streetNumber}":"${input.streetNumber}",
            "{localNumber}":"${input.localNumber}",
            "{city}":"${input.city}",
            "{postcode}":"${input.postcode}",
            "{municipality}":"${input.municipality}",
            "{county}":"${input.county}",
            "{province}":"${input.province}",
            "{isShippingAddress}":"${input.isShippingAddress}",
            "{shippingStreet}":"${
              input.shippingStreet ? input.shippingStreet : input.street
            }",
            "{shippingStreetNumber}":"${
              input.shippingStreetNumber
                ? input.shippingStreetNumber
                : input.streetNumber
            }",
            "{shippingLocalNumber}":"${
              input.shippingLocalNumber
                ? input.shippingLocalNumber
                : input.localNumber
            }",
            "{shippingCity}":"${
              input.shippingCity ? input.shippingCity : input.city
            }",
            "{shippingPostcode}":"${
              input.shippingPostcode ? input.shippingPostcode : input.postcode
            }",
            "{shippingMunicipality}":"${
              input.shippingMunicipality
                ? input.shippingMunicipality
                : input.municipality
            }",
            "{shippingCounty}":"${
              input.shippingCounty ? input.shippingCounty : input.county
            }",
            "{shippingProvince}":"${
              input.shippingProvince ? input.shippingProvince : input.province
            }", 
            "{agent1Function}":"${input.agent1Function}",
            "{agent1Name}":"${input.agent1Name}",
            "{agent1Surname}":"${input.agent1Surname}",
            "{agent1Pesel}":"${input.agent1Pesel}",
            "{agent2Function}":"${input.agent2Function}",
            "{agent2Name}":"${input.agent2Name}",
            "{agent2Surname}":"${input.agent2Surname}",
            "{agent2Pesel}":"${input.agent2Pesel}",
            "{agent3Function}":"${input.agent3Function}",
            "{agent3Name}":"${input.agent3Name}",
            "{agent3Surname}":"${input.agent3Surname}",
            "{agent3Pesel}":"${input.agent3Pesel}",
            "{agent4Function}":"${input.agent4Function}",
            "{agent4Name}":"${input.agent4Name}",
            "{agent4Surname}":"${input.agent4Surname}",
            "{agent4Pesel}":"${input.agent4Pesel}"
          }`,
        }
      )
      setInput({ ...input, pdfFile: pdf_path })
      setPdf(false)

      // setStep(step + 1)
      parseInt(input?.sharesTotal?.replace(",00 zł", "")) > 25000
        ? setStep(step + 1)
        : setStep(step + 2)
    } catch (error) {
      setPdf(false)
      console.warn(error.response.data)
    }
  }

  return (
    <section className="order-actions">
      <div className="container-fluid">
        <div className="order-actions__wrapper">
          <div>
            {step !== 1 && (
              <button
                className="order-actions__button order-actions__button-prev"
                onClick={() => {
                  step === 4 &&
                  parseInt(input?.sharesTotal?.replace(",00 zł", "")) > 25000
                    ? setStep(step - 1)
                    : setStep(step - 2)
                  scrollToElement("#top", { duration: 10 })
                  setInput({ ...input, paymentMethod: "" })
                }}
              >
                <span>
                  <img src={ArrowPrev} alt="" />
                </span>
                Powrót
              </button>
            )}
          </div>
          <span>{step}/6</span>
          <div>
            {step === 5 && (
              <button
                className="order-actions__button order-actions__button-next"
                onClick={handleFormSubmit}
                disabled={!input.paymentMethod}
              >
                {checkoutLoading
                  ? "Przetwarzanie transakcji..."
                  : "Zawieram umowę"}

                <span>
                  <img src={ArrowNext} alt="" />
                </span>
              </button>
            )}
            {step !== 5 && (
              <button
                className="order-actions__button order-actions__button-next"
                onClick={() => {
                  let is_valid = false

                  scrollToElement("#top", { duration: 10 })

                  if (step === 1) {
                    is_valid = handleValidationStepFirst()
                    setInput({ ...input, email: customer?.email })
                  }

                  if (step === 2) {
                    is_valid = handleValidationStepSecond()
                  }

                  if (step === 3) {
                    is_valid = handleValidationStepThird()
                  }

                  if (step === 4) {
                    is_valid = handleValidationStepFourth()
                  }

                  if (step === 5) {
                    is_valid = true
                  }

                  is_valid
                    ? step === 3 &&
                      parseInt(input?.sharesTotal?.replace(",00 zł", "")) >=
                        25000
                      ? generatePdf()
                      : step === 2 &&
                        parseInt(input?.sharesTotal?.replace(",00 zł", "")) <=
                          25000
                      ? generatePdf()
                      : setStep(step + 1)
                    : toast.warning("Formularz zawiera błędy.")
                }}
                disabled={!input.clientType}
              >
                Następny krok
                <span>
                  <img src={ArrowNext} alt="" />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Actions
