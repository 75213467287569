import "./styles.scss"

import React from "react"

import Input from "components/Input"
import Checkbox from "components/Checkbox"

const ContactData = ({ input, setInput, validationErrors }) => {
  return (
    <section className="order-contact-data">
      <div className="container-fluid">
        <h3>Dane kontaktowe</h3>
        <div className="row">
          <div className="col-xl-4 col-sm-6">
            <h4>
              Adres{" "}
              {input.clientType === "company" ? "siedziby" : "zamieszkania"}
            </h4>
            <div className="order-contact-data__form order-contact-data__form--headquarters">
              <Input
                type="text"
                label="Ulica*"
                value={input.street}
                onChange={e => setInput({ ...input, street: e.target.value })}
                error={validationErrors.street}
              />
              <div className="row">
                <div className="col-sm-6">
                  <Input
                    type="text"
                    label="Nr domu*"
                    value={input.streetNumber}
                    onChange={e =>
                      setInput({ ...input, streetNumber: e.target.value })
                    }
                    error={validationErrors.streetNumber}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    type="text"
                    label="Nr lokalu"
                    value={input.localNumber}
                    onChange={e =>
                      setInput({ ...input, localNumber: e.target.value })
                    }
                  />
                </div>
              </div>
              <Input
                type="text"
                label="Miejscowość*"
                value={input.city}
                onChange={e => setInput({ ...input, city: e.target.value })}
                error={validationErrors.city}
              />
              <Input
                type="text"
                label="Kod pocztowy*"
                value={input.postcode}
                onChange={e => setInput({ ...input, postcode: e.target.value })}
                error={validationErrors.postcode}
              />
              <Input
                type="text"
                label="Gmina"
                value={input.municipality}
                onChange={e =>
                  setInput({ ...input, municipality: e.target.value })
                }
              />
              <Input
                type="text"
                label="Powiat"
                value={input.county}
                onChange={e => setInput({ ...input, county: e.target.value })}
              />
              <Input
                type="text"
                label="Województwo*"
                value={input.province}
                onChange={e => setInput({ ...input, province: e.target.value })}
                error={validationErrors.province}
              />
            </div>
          </div>

          <div className="order-contact-data__divider" />

          <div className="col-xl-4 col-sm-6">
            <h4>Adres do korespondencji</h4>
            <Checkbox
              className="checkbox--regular"
              checked={!input.isShippingAddress}
              onChange={() =>
                setInput({
                  ...input,
                  isShippingAddress: !input.isShippingAddress,
                })
              }
            >
              taki sam jak zamieszkania
            </Checkbox>

            {input.isShippingAddress && (
              <div className="order-contact-data__form">
                <Input
                  type="text"
                  label="Ulica*"
                  value={input.shippingStreet}
                  onChange={e =>
                    setInput({ ...input, shippingStreet: e.target.value })
                  }
                  error={validationErrors.shippingStreet}
                />
                <div className="row">
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      label="Nr domu*"
                      value={input.shippingStreetNumber}
                      onChange={e =>
                        setInput({
                          ...input,
                          shippingStreetNumber: e.target.value,
                        })
                      }
                      error={validationErrors.shippingStreetNumber}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      label="Nr lokalu"
                      value={input.shippingLocalNumber}
                      onChange={e =>
                        setInput({
                          ...input,
                          shippingLocalNumber: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <Input
                  type="text"
                  label="Miejscowość*"
                  value={input.shippingCity}
                  onChange={e =>
                    setInput({
                      ...input,
                      shippingCity: e.target.value,
                    })
                  }
                  error={validationErrors.shippingCity}
                />
                <Input
                  type="text"
                  label="Kod pocztowy*"
                  value={input.shippingPostcode}
                  onChange={e =>
                    setInput({
                      ...input,
                      shippingPostcode: e.target.value,
                    })
                  }
                  error={validationErrors.shippingPostcode}
                />
                <Input
                  type="text"
                  label="Gmina"
                  value={input.shippingMunicipality}
                  onChange={e =>
                    setInput({
                      ...input,
                      shippingMunicipality: e.target.value,
                    })
                  }
                />
                <Input
                  type="text"
                  label="Powiat"
                  value={input.shippingCounty}
                  onChange={e =>
                    setInput({
                      ...input,
                      shippingCounty: e.target.value,
                    })
                  }
                />
                <Input
                  type="text"
                  label="Województwo*"
                  value={input.shippingProvince}
                  onChange={e =>
                    setInput({
                      ...input,
                      shippingProvince: e.target.value,
                    })
                  }
                  error={validationErrors.shippingProvince}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactData
