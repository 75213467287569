import "./styles.scss"

import React from "react"

import Checkbox from "components/Checkbox"
import OrderDocs from "components/OrderDocs"

const Summary = ({ input, setInput, validationErrors }) => {
  return (
    <section className="order-summary">
      <div className="container-fluid">
        <h3>Podsumowanie umowy inwestycyjnej - pożyczki</h3>
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-sm-6">
                <h4 className="with-border">Inwestycja</h4>
                <h3>Rezydencja Sikorskiego</h3>

                <h4>Kwota inwestycji</h4>
                <div className="order-summary__amount">{input.sharesTotal}</div>

                <div>
                  <p className="order-summary__item">
                    Stopa zwrotu inwestycji{" "}
                    <strong className="blue">
                      {input?.productId === 1105 ? "10,61%*" : "10%"}
                    </strong>
                  </p>
                  {/* <p className="order-summary__item">
                    Wartość jednego udziału{" "}
                    <strong>{input.shareEmissionPrice}*</strong>
                  </p>
                  <p className="order-summary__item">
                    Cel <strong>14 000 000,00 zł</strong>
                  </p>
                  <p className="order-summary__item">
                    Do końca <strong>-</strong>
                  </p> */}
                </div>

                <p className="order-summary__info">
                  {input?.productId === 1105 &&
                    "* Oprocentowanie zostało przyjęte na dzień 02.04.2024 roku, przy założeniu, że na dzień 02.04.2024 roku WIBOR 3M wynosi 5,86%, a marża 4,75%. Oprocentowanie zmienne ustalane jest w oparciu o zmienną stopę bazową WIBOR 3M oraz stałą marżę. Do zmiennej stopy procentowej dodawana będzie stała marża w wysokości 4,75%. Ostateczna wartość szacowanych zysków może więc różnic się od tej zaprezentowanej w wyliczeniach."}
                </p>
              </div>

              <div className="col-sm-6">
                <h4 className="with-border">Dane podstawowe Inwestora</h4>
                <div className="order-summary__data">
                  {input.investor && (
                    <p>
                      Inwestor <strong>{input.investor || "-"}</strong>
                    </p>
                  )}
                  {input.krs && (
                    <p>
                      Numer KRS Inwestora <strong>{input.krs || "-"}</strong>
                    </p>
                  )}
                  {input.court && (
                    <p>
                      Sąd <strong>{input.court || "-"}</strong>
                    </p>
                  )}
                  {input.department && (
                    <p>
                      Wydział <strong>{input.department || "-"}</strong>
                    </p>
                  )}
                  {input.regon && (
                    <p>
                      Numer REGON Inwestora{" "}
                      <strong>{input.regon || "-"}</strong>
                    </p>
                  )}
                  <p>
                    Imiona i nazwisko{" "}
                    <strong>
                      {input.firstName || "-"} {input.secondFirstName || "-"}{" "}
                      {input.lastName || "-"}
                    </strong>
                  </p>
                  <p>
                    PESEL <strong>{input.pesel || "-"}</strong>
                  </p>
                  <p>
                    Numer i seria dowodu osobistego{" "}
                    <strong>{input.idNumber || "-"}</strong>
                  </p>
                  <p>
                    Numer telefonu Inwestora{" "}
                    <strong>{input.phone || "-"}</strong>
                  </p>
                  <p>
                    Rachunek bankowy <strong>{input.bankAccount || "-"}</strong>
                  </p>
                  <p>
                    Adres poczty elektronicznej{" "}
                    <strong>{input.email || "-"}</strong>
                  </p>
                  <p>
                    Numer NIP <strong>{input.nip || "-"}</strong>
                  </p>
                  <p>
                    Urząd Skarbowy <strong>{input.taxOffice || "-"}</strong>
                  </p>
                  {/* <p>
                    Liczba nowo utworzonych udziałów w podwyższonym kapitale
                    zakładowym Dewelopera zaproponowanych do objęcia Inwestorowi{" "}
                    <strong>{input.sharesQuantity || "-"}</strong>
                  </p>
                  {input.sharesTotal && (
                    <p>
                      Łączna kwota objęcia Udziałów {input.sharesTotal || "-"}
                    </p>
                  )}
                  <p>
                    Cena nominalna jednego udziału{" "}
                    <strong>{input.sharePrice || "-"}</strong>
                  </p>
                  <p>
                    Cena emisyjna jednego udziału{" "}
                    <strong>{input.shareEmissionPrice || "-"}</strong>
                  </p> */}
                  <p>
                    Łączna kwota udzielonej pożyczki inwestycyjnej{" "}
                    <strong>{input.sharesEmissionTotal || "-"}</strong>
                  </p>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="order-summary__divider"></div>
                <h4 className="with-border">Adres zamieszkania</h4>
                <div className="order-summary__data order-summary__data--compact">
                  <p>
                    Ulica <strong>{input.street || "-"}</strong>
                  </p>
                  <p>
                    Numer domu/lokalu{" "}
                    <strong>
                      {input.streetNumber} {input.localNumber}
                    </strong>
                  </p>
                  <p>
                    Miejscowść <strong>{input.city || "-"}</strong>
                  </p>
                  <p>
                    Kod pocztowy <strong>{input.postcode || "-"}</strong>
                  </p>
                  <p>
                    Gmina <strong>{input.municipality || "-"}</strong>
                  </p>
                  <p>
                    Powiat <strong>{input.county || "-"}</strong>
                  </p>
                  <p>
                    Województwo <strong>{input.province || "-"}</strong>
                  </p>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="order-summary__divider order-summary__divider-desktop"></div>
                <h4 className="with-border">Adres do korespondencji</h4>
                {input?.isShippingAddress ? (
                  <div className="order-summary__data order-summary__data--compact">
                    <p>
                      Ulica <strong>{input.shippingStreet || "-"}</strong>
                    </p>
                    <p>
                      Numer domu/lokalu{" "}
                      <strong>
                        {input.shippingStreetNumber} {input.shippingLocalNumber}
                      </strong>
                    </p>
                    <p>
                      Miejscowść <strong>{input.shippingCity || "-"}</strong>
                    </p>
                    <p>
                      Kod pocztowy{" "}
                      <strong>{input.shippingPostcode || "-"}</strong>
                    </p>
                    <p>
                      Gmina <strong>{input.shippingMunicipality || "-"}</strong>
                    </p>
                    <p>
                      Powiat <strong>{input.shippingCounty || "-"}</strong>
                    </p>
                    <p>
                      Województwo{" "}
                      <strong>{input.shippingProvince || "-"}</strong>
                    </p>
                  </div>
                ) : (
                  <Checkbox className="checkbox--regular" checked="checked">
                    taki sam jak zamieszkania
                  </Checkbox>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <h4>Umowa wraz z załącznikami</h4>
            <div className="order-summary__docs">
              <p>
                Umowa wraz z załącznikami zostanie także wysłana na podany adres
                e-mail:
              </p>
              <ul>
                {input.pdfFile && (
                  <li>
                    <a href={input.pdfFile} target="_blank" rel="noreferrer">
                      Umowa
                    </a>
                  </li>
                )}
                <OrderDocs input={input} type={input.clientType} />
              </ul>
              <Checkbox
                className="checkbox--regular"
                name="legalFirst"
                checked={input.legalFirst}
                onChange={() =>
                  setInput({ ...input, legalFirst: !input.legalFirst })
                }
                error={validationErrors.legalFirst}
              >
                *Oświadczam, że zapoznałem się z treścią Umowy inwestycyjnej i
                załącznikami do Umowy i rozumiem jej treść.
              </Checkbox>
              <Checkbox
                className="checkbox--regular"
                name="legalSecond"
                checked={input.legalSecond}
                onChange={() =>
                  setInput({ ...input, legalSecond: !input.legalSecond })
                }
                error={validationErrors.legalSecond}
              >
                *Oświadczam, że zapoznałem się z formularzem podstawowych
                informacji oraz rozumiem jego treść. Jestem świadomy ryzyka
                związanego z inwestowaniem.
              </Checkbox>
            </div>

            {parseInt(input?.sharesTotal?.replace(",00 zł", "")) > 25000 && (
              <div className="order-summary__meeting">
                <h4>Miejsce i czas spotkania u notariusza</h4>
                <p>
                  Miasto <strong>{input.meetingCity || "-"}</strong>
                </p>
                <p>
                  Preferowany dzień <strong>{input.meetingDate || "-"}</strong>
                </p>
                <p>
                  Preferowane godziny w dniu pierwszym{" "}
                  <strong>{input.meetingHour || "-"}</strong>
                </p>
                {input.meetingDateOptional && (
                  <p>
                    Opcjonalny dzień{" "}
                    <strong>{input.meetingDateOptional}</strong>
                  </p>
                )}
                {input.meetingHourOptional && (
                  <p>
                    Opcjonalne godziny w dniu pierwszym{" "}
                    <strong>{input.meetingHourOptional}</strong>
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Summary
