/* eslint-disable react-hooks/exhaustive-deps */

import "./styles.scss"

import React, { useState, useEffect } from "react"
import { v4 } from "uuid"

import { Individual, Company, Representative } from "./components"

// import IcoMinus from "assets/icons/order-minus.svg"
// import IcoPlus from "assets/icons/order-plus.svg"
import IcoDocs from "assets/icons/docs.svg"
import { withPrefix } from "gatsby"

const BasicData = ({ data, input, setInput, validationErrors }) => {
  const [productInitial, setproductInitial] = useState(null)
  // const [productQuantity, setProductQuantity] = useState(null)
  // const [productPrice, setProductPrice] = useState(productInitial)

  // const rmQuantity = ({ price }) => {
  //   setProductPrice(
  //     parseInt(price) * (productQuantity === 1 ? 1 : productQuantity - 1)
  //   )
  //   setProductQuantity(productQuantity === 1 ? 1 : productQuantity - 1)
  // }

  // const addQuantity = ({ price }) => {
  //   setProductPrice(parseInt(price) * (productQuantity + 1))
  //   setProductQuantity(productQuantity + 1)
  // }

  useEffect(() => {
    setproductInitial(parseInt(data?.cart?.total))
    // setProductQuantity(data?.cart?.contents?.nodes[0]?.quantity)
    // setProductPrice(
    //   parseInt(data?.cart?.contents?.nodes[0]?.product?.node?.price)
    // )
  }, [data])

  useEffect(() => {
    !input.customID && setInput({ ...input, customID: v4() })
  })

  const contentBasicData = {
    input,
    setInput,
    validationErrors,
  }

  return (
    <section className="order-basic-data">
      <div className="container-fluid">
        <h3>{data?.cart?.contents?.nodes[0]?.product?.node?.name}</h3>
        <div className="row">
          <div className="col-lg-4">
            <h4>Kielce</h4>
            <div className="order-basic-data__quantity">
              <h4>Kwota inwestycji:</h4>
              <div className="order-basic-data__input">
                {/* <button
                  onClick={() => {
                    rmQuantity({
                      price: 2500,
                    })
                  }}
                >
                  <img src={IcoMinus} alt="" />
                </button> */}
                <div>{!isNaN(productInitial) ? productInitial : "-"} zł</div>
                {/* <button
                  onClick={() => {
                    addQuantity({
                      price: 2500,
                    })
                  }}
                >
                  <img src={IcoPlus} alt="" />
                </button> */}
              </div>
              {/* <p>
                Liczba udziałów:{" "}
                <strong>{productQuantity ? productQuantity : "-"}</strong>
              </p> */}
            </div>

            <div>
              <p className="order-basic-data__item">
                Stopa zwrotu inwestycji{" "}
                <strong className="blue">
                  {data?.cart?.contents?.nodes[0]?.product?.node?.databaseId ===
                  1105
                    ? "10,61%*"
                    : "10%"}
                </strong>
              </p>
              {/* <p className="order-basic-data__item">
                Wartość jednego udziału{" "}
                <strong>{!isNaN(productPrice) ? productPrice : "-"} zł</strong>
              </p> */}
              {/* <p className="order-basic-data__item">
                Cel <strong>14 000 000,00 zł</strong>
              </p>
              <p className="order-basic-data__item">
                Do końca <strong>-</strong>
              </p> */}
            </div>

            <p className="order-basic-data__info">
              {data?.cart?.contents?.nodes[0]?.product?.node?.databaseId ===
                1105 &&
                "* Oprocentowanie zostało przyjęte na dzień 02.04.2024 roku, przy założeniu, że na dzień 02.04.2024 roku WIBOR 3M wynosi 5,86%, a marża 4,75%. Oprocentowanie zmienne ustalane jest w oparciu o zmienną stopę bazową WIBOR 3M oraz stałą marżę. Do zmiennej stopy procentowej dodawana będzie stała marża w wysokości 4,75%. Ostateczna wartość szacowanych zysków może więc różnic się od tej zaprezentowanej w wyliczeniach."}
            </p>
          </div>

          <div className="col-lg-4">
            <h4>Klient:</h4>
            <button
              className={`order-basic-data__cta${
                input.clientType === "individual" ? " current" : ""
              }`}
              onClick={() => {
                setInput({ ...input, clientType: "individual" })
              }}
            >
              <span /> Osoba fizyczna
            </button>
            {/* <button
              className={`order-basic-data__cta${
                input.clientType === "company" ? " current" : ""
              }`}
              onClick={() => {
                setInput({ ...input, clientType: "company" })
              }}
            >
              <span /> Osoba prawna
            </button> */}

            {input.clientType === "individual" && (
              <a
                href={
                  parseInt(input?.sharesTotal?.replace(",00 zł", "")) >= 25000
                    ? input.productId === 1107
                      ? withPrefix(
                          "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_POWYZEJ_25000_STALA_MARZA/24_02_26_TRUST_TRUST_15_umowa_inwestycyjna_STALA_MARZA.pdf"
                        )
                      : withPrefix(
                          "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_POWYZEJ_25000_ZMIENNA_MARZA/24_02_23_TRUST_PROJEKT_15_umowa_inwestycyjna_ZMIENNA_MARZA.pdf"
                        )
                    : withPrefix(
                        "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_PONIZEJ_25000/24_02_23_TRUST_PROJEKT_15_umowa_inwestycyjna_PONIZEJ_25000.pdf"
                      )
                }
                target="_blank"
                rel="noreferrer"
                className="order-basic-data__pdf"
              >
                <img src={IcoDocs} alt="" /> Umowa inwestycyjna pożyczka - osoba
                fizyczna (wzór).pdf
              </a>
            )}
            {/* {input.clientType === "company" && (
              <a
                href={withPrefix(
                  "/Umowa_inwestycyjna_osoba_prawna_wzor_v3.pdf"
                )}
                target="_blank"
                rel="noreferrer"
                className="order-basic-data__pdf"
              >
                <img src={IcoDocs} alt="" /> Umowa inwestycyjna - osoba prawna
                (wzór).pdf
              </a>
            )} */}
            {input.clientType === "company" && (
              <Representative {...contentBasicData} />
            )}
          </div>

          <div className="col-lg-4">
            <div className="order-basic-data__form-divider-mobile" />
            {input.clientType && <h4>Wprowadź dane:</h4>}
            {input.clientType === "individual" && (
              <Individual {...contentBasicData} />
            )}
            {input.clientType === "company" && (
              <Company {...contentBasicData} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BasicData
