import React from "react"

import Input from "components/Input"

const Company = ({ input, setInput, validationErrors }) => {
  return (
    <div className="order-basic-data__form">
      <Input
        type="text"
        label="Inwestor*"
        value={input.investor}
        onChange={e => setInput({ ...input, investor: e.target.value })}
        error={validationErrors.investor}
      />
      <Input
        type="number"
        label="Numer KRS Inwestora*"
        value={input.krs}
        onChange={e => setInput({ ...input, krs: e.target.value })}
        error={validationErrors.krs}
      />
      <p>
        Oznaczenie sądu rejestrowego, w którym przechowywana jest dokumentacja
        spółki
      </p>
      <Input
        type="text"
        label="Sąd*"
        value={input.court}
        onChange={e => setInput({ ...input, court: e.target.value })}
        error={validationErrors.court}
      />
      <Input
        type="text"
        label="Wydział*"
        value={input.department}
        onChange={e => setInput({ ...input, department: e.target.value })}
        error={validationErrors.department}
      />
      <Input
        type="number"
        label="Numer REGON Inwestora*"
        value={input.regon}
        onChange={e => setInput({ ...input, regon: e.target.value })}
        error={validationErrors.regon}
      />
      <Input
        type="number"
        label="Numer Identyfikacji Podatkowej (NIP) Inwestora*"
        value={input.nip}
        onChange={e => setInput({ ...input, nip: e.target.value })}
        error={validationErrors.nip}
      />
      <Input
        type="tel"
        label="Numer telefonu Inwestora*"
        value={input.phone}
        onChange={e => setInput({ ...input, phone: e.target.value })}
        error={validationErrors.phone}
      />
      <Input
        type="text"
        label="Rachunek bankowy Inwestora*"
        value={input.bankAccount}
        onChange={e => setInput({ ...input, bankAccount: e.target.value })}
        error={validationErrors.bankAccount}
      />
      {/* <Input
        type="email"
        label="Adres poczty elektronicznej Inwestora"
        value={input.email}
        readOnly
      /> */}
      <Input
        type="text"
        label="Urząd Skarbowy właściwy dla Inwestora*"
        value={input.taxOffice}
        onChange={e => setInput({ ...input, taxOffice: e.target.value })}
        error={validationErrors.taxOffice}
      />
      <Input
        type="text"
        label="Liczba nowo utworzonych udziałów w podwyższonym kapitale zakładowym Dewelopera zaoferowanych do objęcia Inwestorowi"
        value={input.sharesQuantity}
        readOnly
      />
      <Input
        type="text"
        label="Łączna kwota objęcia Udziałów"
        value={input.sharesTotal}
        readOnly
      />
      <Input
        type="text"
        label="Cena nominalna jednego udziału"
        value={input.sharePrice}
        readOnly
      />
      <Input
        type="text"
        label="Cena emisyjna jednego udziału"
        value={input.shareEmissionPrice}
        readOnly
      />
      <Input
        type="text"
        label="Łączna cena emisyjna zaproponowanych do objęcia udziałów"
        value={input.sharesEmissionTotal}
        readOnly
      />
    </div>
  )
}

export default Company
