import "./styles.scss"

import React from "react"

import Select from "components/Select"
import DateInput from "components/DateInput"

const MeetingTime = ({ input, setInput, validationErrors }) => {
  return (
    <section className="order-meeting-time">
      <div className="container-fluid">
        <h3>Spotkanie w sprawie notarialnego poświadczenia podpisów</h3>
        <p className="order-meeting-time__description">
          Skontaktujemy się w celu potwierdzenia rezerwacji terminu
        </p>
        <div className="row">
          <div className="col-md-4">
            <Select
              label="Preferowane miasto"
              value={input.meetingCity}
              onChange={e =>
                setInput({ ...input, meetingCity: e.target.value })
              }
              options={[
                { label: "Wybierz miasto", value: "", disabled: true },
                { label: "Białystok", value: "Białystok" },
                { label: "Bielsko Biała", value: "Bielsko Biała" },
                { label: "Bydgoszcz", value: "Bydgoszcz" },
                { label: "Gdańsk", value: "Gdańsk" },
                { label: "Gorzów Wielkopolski", value: "Gorzów Wielkopolski" },
                { label: "Katowice", value: "Katowice" },
                { label: "Kielce", value: "Kielce" },
                { label: "Kraków", value: "Kraków" },
                { label: "Lublin", value: "Lublin" },
                { label: "Łódź", value: "Łódź" },
                { label: "Olsztyn", value: "Olsztyn" },
                { label: "Opole", value: "Opole" },
                { label: "Poznań", value: "Poznań" },
                { label: "Rzeszów", value: "Rzeszów" },
                { label: "Szczecin", value: "Szczecin" },
                { label: "Warszawa", value: "Warszawa" },
                { label: "Wrocław", value: "Wrocław" },
              ]}
              error={validationErrors.meetingCity}
            />
          </div>
          <div className="col-md-4">
            <DateInput
              label="Preferowany dzień"
              name="date"
              value={input.meetingDate}
              onChange={e => setInput({ ...input, meetingDate: e })}
              error={validationErrors.meetingDate}
            />
            <Select
              label="Preferowane godziny w pierwszym dniu"
              value={input.meetingHour}
              onChange={e =>
                setInput({ ...input, meetingHour: e.target.value })
              }
              options={[
                { label: "Wybierz godzinę", value: "", disabled: true },
                { label: "8:00", value: "8:00" },
                { label: "9:00", value: "9:00" },
                { label: "10:00", value: "10:00" },
                { label: "11:00", value: "11:00" },
                { label: "12:00", value: "12:00" },
                { label: "13:00", value: "13:00" },
                { label: "14:00", value: "14:00" },
                { label: "15:00", value: "15:00" },
                { label: "16:00", value: "16:00" },
                { label: "17:00", value: "17:00" },
                { label: "18:00", value: "18:00" },
              ]}
              error={validationErrors.meetingHour}
            />
          </div>
          <div className="col-md-4">
            <DateInput
              label="Drugi preferowany dzień"
              name="date"
              value={input.meetingDateOptional}
              onChange={e => setInput({ ...input, meetingDateOptional: e })}
            />
            <Select
              label="Preferowane godziny w drugim terminie"
              value={input.meetingHourOptional}
              onChange={e =>
                setInput({ ...input, meetingHourOptional: e.target.value })
              }
              options={[
                { label: "Wybierz godzinę", value: "", disabled: true },
                { label: "8:00", value: "8:00" },
                { label: "9:00", value: "9:00" },
                { label: "10:00", value: "10:00" },
                { label: "11:00", value: "11:00" },
                { label: "12:00", value: "12:00" },
                { label: "13:00", value: "13:00" },
                { label: "14:00", value: "14:00" },
                { label: "15:00", value: "15:00" },
                { label: "16:00", value: "16:00" },
                { label: "17:00", value: "17:00" },
                { label: "18:00", value: "18:00" },
              ]}
            />
            <p className="order-meeting-time__info">
              Preferowany dzień oraz godzina spotkania, w sprawie podpisania
              aktu notarialnego, zostaną finalnie potwierdzone przez naszego
              doradcę po pomyślnym zarezerwowaniu terminu w Kancelarii
              Notarialnej.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MeetingTime
