import "./styles.scss"

import React from "react"

const LazyPlaceholder = () => {
  return (
    <div className="lazy-placeholder">
      <div className="lazy-placeholder__ring"></div>
    </div>
  )
}

export default LazyPlaceholder
