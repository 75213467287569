import "./styles.scss"

import React, { useState } from "react"

const Select = ({
  label,
  id,
  name,
  value,
  disabled,
  onChange,
  placeholder,
  required,
  className,
  options,
  error,
}) => {
  const [filled, setFilled] = useState(false)

  const handleFocus = () => {
    setFilled(true)
  }

  const checkInput = ({ target: { value } }) => {
    if (value.length > 0) {
      setFilled(true)
    } else {
      setFilled(false)
    }
  }

  return (
    <div
      className={`select${filled ? " fill" : ""}${
        className ? ` ${className}` : ""
      }${!label ? " select--no-label" : ""}`}
    >
      {label && (
        <label className="select__label" htmlFor={name}>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
      <select
        className="select__select"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={checkInput}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        defaultValue=""
      >
        {options.map((item, index) => (
          <option value={item.value} key={index} disabled={item.disabled}>
            {item.label}
          </option>
        ))}
      </select>
      {error === "error" && <div className="select__error">Pole wymagane</div>}
    </div>
  )
}

export default Select
