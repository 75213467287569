import "./styles.scss"

import React from "react"
import { withPrefix } from "gatsby"

const OrderDocs = ({ input }) => {
  return parseInt(input?.sharesTotal?.replace(",00 zł", "")) >= 25000 ? (
    input.productId === 1107 ? (
      <>
        <li>
          <a
            href={withPrefix(
              "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_POWYZEJ_25000_STALA_MARZA/24_02_26_TRUST_TRUST_15_umowa_poreczenia_STALA_MARZA.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 1 - UMOWA PORĘCZENIA
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_POWYZEJ_25000_STALA_MARZA/24_02_26_TRUST_TRUST_15_umowa_zastawu_STALA_MARZA.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 2 - UMOWA ZASTAWU
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_POWYZEJ_25000_STALA_MARZA/24_02_26_TRUST_TRUST_15_formularz_STALA_MARZA.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 3 - FORMULARZ PODSTAWOWYCH INFORMACJI
          </a>
        </li>
      </>
    ) : (
      <>
        <li>
          <a
            href={withPrefix(
              "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_POWYZEJ_25000_ZMIENNA_MARZA/24_02_23_TRUST_PROJEKT_15_umowa_poreczenia_ZMIENNA_MARZA.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 1 - UMOWA PORĘCZENIA
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_POWYZEJ_25000_ZMIENNA_MARZA/24_02_23_TRUST_PROJEKT_15_umowa_zastawu_ZMIENNA_MARZA.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 2 - UMOWA ZASTAWU
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_POWYZEJ_25000_ZMIENNA_MARZA/24_02_23_TRUST_PROJEKT_15_formularz_ZMIENNA_MARZA.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 3 - FORMULARZ PODSTAWOWYCH INFORMACJI
          </a>
        </li>
      </>
    )
  ) : (
    <>
      <li>
        <a
          href={withPrefix(
            "/projekt-15/24_02_26_TRUST_PROJEKT_15_umowa_inw_zalaczniki_PONIZEJ_25000/24_02_23_TRUST_PROJEKT_15_formularz_PONIZEJ_25000.pdf"
          )}
          target="_blank"
          rel="noreferrer"
        >
          Załącznik nr 1 - FORMULARZ PODSTAWOWYCH INFORMACJI
        </a>
      </li>
    </>
  )
}

export default OrderDocs
