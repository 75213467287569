import { gql } from "@apollo/client"

const GET_CUSTOMER = gql`
  query GET_CUSTOMER {
    customer {
      id
      firstName
      lastName
      username
      email
      showPartnerOrder
      billing {
        address1
        address2
        city
        company
        email
        country
        firstName
        phone
        lastName
        postcode
        state
      }
      shipping {
        address1
        address2
        city
        company
        country
        email
        firstName
        lastName
        phone
        postcode
        state
      }
      orders {
        nodes {
          id
          orderNumber
          orderKey
          date
          total
          status
          pesel
          idNumber
          clientType
          bankAccount
          nip
          taxOffice
          investor
          krs
          court
          department
          sharesQuantity
          sharePrice
          shareEmissionPrice
          sharesTotal
          sharesEmissionTotal
          pdfFile
          annex_to_contracts {
            annexToContracts {
              description
              file {
                sourceUrl
                mediaItemUrl
              }
            }
          }
          lineItems {
            nodes {
              product {
                name
                acfProduct {
                  dokumentyOsobaFizyczna {
                    nazwaPliku
                    plik {
                      sourceUrl
                      mediaItemUrl
                    }
                  }
                  dokumentyOsobaPrawna {
                    nazwaPliku
                    plik {
                      sourceUrl
                      mediaItemUrl
                    }
                  }
                  dokumentyKwotaPonizej25Tys {
                    nazwaPliku
                    plik {
                      sourceUrl
                      mediaItemUrl
                    }
                  }
                  dokumentyKwotaPowyzej25Tys {
                    nazwaPliku
                    plik {
                      sourceUrl
                      mediaItemUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
      partnerOrders {
        partnerId
        id
        orderNumber
        orderKey
        date
        total
        status
        pesel
        idNumber
        clientType
        bankAccount
        nip
        taxOffice
        investor
        krs
        court
        department
        sharesQuantity
        sharePrice
        shareEmissionPrice
        sharesTotal
        sharesEmissionTotal
        pdfFile
        annex_to_contracts {
          annexToContracts {
            description
            file {
              sourceUrl
              mediaItemUrl
            }
          }
        }
        customer {
          firstName
          lastName
        }
        lineItems {
          nodes {
            product {
              name
              acfProduct {
                dokumentyOsobaFizyczna {
                  nazwaPliku
                  plik {
                    sourceUrl
                    mediaItemUrl
                  }
                }
                dokumentyOsobaPrawna {
                  nazwaPliku
                  plik {
                    sourceUrl
                    mediaItemUrl
                  }
                }
                dokumentyKwotaPonizej25Tys {
                  nazwaPliku
                  plik {
                    sourceUrl
                    mediaItemUrl
                  }
                }
                dokumentyKwotaPowyzej25Tys {
                  nazwaPliku
                  plik {
                    sourceUrl
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
      pesel
      idNumber
      bankAccount
      nip
      taxOffice
      investor
      krs
      court
      department
    }
  }
`

export default GET_CUSTOMER
