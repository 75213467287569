import { gql } from "@apollo/client"

const CHECKOUT_MUTATION = gql`
  mutation CHECKOUT_MUTATION($input: CheckoutInput!) {
    checkout(input: $input) {
      order {
        id
        cartHash
        databaseId
        orderKey
        orderNumber
        paymentMethod
        total
        shippingTotal
        customer {
          email
        }
        lineItems {
          nodes {
            quantity
            total
            totalTax
            product {
              databaseId
              name
            }
          }
        }
        pesel
        idNumber
        partnerId
        clientType
        bankAccount
        nip
        regon
        taxOffice
        investor
        krs
        court
        department
        sharePrice
        shareEmissionPrice
        sharesTotal
        sharesEmissionTotal
        sharesQuantity
        meetingCity
        meetingDate
        meetingHour
        meetingDateOptional
        meetingHourOptional
        pdfFile
        agent1Function
        agent1Name
        agent1Surname
        agent1Pesel
        agent2Function
        agent2Name
        agent2Surname
        agent2Pesel
        agent3Function
        agent3Name
        agent3Surname
        agent3Pesel
        agent4Function
        agent4Name
        agent4Surname
        agent4Pesel
      }
    }
  }
`

export default CHECKOUT_MUTATION
