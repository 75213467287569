import "./styles.scss"

import React from "react"

import LazyPlaceholder from "components/LazyPlaceholder"

const Overlay = () => {
  return (
    <div className="order-overlay">
      <LazyPlaceholder />
      <h3>Twoja umowa właśnie się generuje</h3>
    </div>
  )
}

export default Overlay
